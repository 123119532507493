import React from 'react';
import './project-type.scss'
import { Link } from "react-router-dom";
import SvgAddForm from '../../../../../assets/icons/js/AddForm';
import { Loader } from '../../../../../_shared/components/loader/loader';
import ProjectTypeApi from './api/project-type-api';
import { useQuery } from 'react-query';
import { IProjectType } from './model/project-type.interface';
import { ProjectTypeCard } from '../../../../../_shared/components/project-type-card/project-type-card';
import Pagination from "../../../../../_shared/components/pagination/pagination";


export default function ProjectType() {
  const projectTypes = useQuery('allProjectTypes', () => ProjectTypeApi.getAll());

  const [draftPage, setDraftPage] = React.useState<number>(1);
  const [draftPageSize, setDraftPageSize] = React.useState<number>(10);

  const [publishedPage, setPublishedPage] = React.useState<number>(1);
  const [publishedPageSize, setPublishedPageSize] = React.useState<number>(10);


  const draftProjectTypes = useQuery(['draftProjectTypes', draftPage, draftPageSize], () => ProjectTypeApi.getAllPaginated({
    page: draftPage,
    pageSize: draftPageSize,
    status: 'draft'
  }));

  const publishedProjectTypes = useQuery(['publishedProjectTypes', publishedPage, publishedPageSize], () => ProjectTypeApi.getAllPaginated({
    page: publishedPage,
    pageSize: 10,
    status: 'published'
  }));

  const handleDraftPagination = (page: number, pageSize: number) => {
    setDraftPage(page);
    setDraftPageSize(pageSize);
  }

  const handlePublishedPagination = (page: number, pageSize: number) => {
    setPublishedPage(page);
    setPublishedPageSize(pageSize);
  }

  return (
    <div className="project-type">
      <>
        <div className="column">
          <div style={{marginTop: 0}} className="section-title">
            <h4>Drafts</h4>
          </div>
          <div className="row justify-space-end">
            {draftProjectTypes.data && draftProjectTypes?.data?.pagination?.totalElements > 0 &&
              <Pagination totalItems={draftProjectTypes?.data?.pagination.totalElements}
                          currentPage={draftPage} pageSize={draftPageSize} onChange={handleDraftPagination}
                          showSizeChanger={true}/>
            }
          </div>
          <div className="row flex-wrap">
            <Link to="/settings/project-type" className="add-new card">
              <div className="body">
                <SvgAddForm/>
                <span className="text">Add project type</span>
              </div>
            </Link>
            {draftProjectTypes.isLoading ? <Loader/> :
              < >
                {draftProjectTypes.data && draftProjectTypes?.data?.content?.map((projectType: IProjectType, i: number) => {
                  return (
                    <Link to={'/settings/project-type?id=' + projectType.id} key={i}>
                      <ProjectTypeCard projectType={projectType}/>
                    </Link>
                  )
                })}
              </>
            }
          </div>
        </div>
        <div className="column">
          <div className="section-title">
            <h4>Published</h4>
          </div>
          <div className="row justify-space-end">
            {publishedProjectTypes.data && publishedProjectTypes?.data?.pagination?.totalElements > 0 &&
              <Pagination totalItems={publishedProjectTypes?.data?.pagination.totalElements}
                          currentPage={publishedPage} pageSize={publishedPageSize} onChange={handlePublishedPagination}
                          showSizeChanger={true}/>
            }
          </div>
          <div className="row flex-wrap">
            {publishedProjectTypes.isLoading ? <Loader/> :
              <div className="row flex-wrap">
                {publishedProjectTypes.data && publishedProjectTypes?.data?.content?.map((projectType: IProjectType, i: number) => {
                  return (
                    <Link to={'/settings/project-type?id=' + projectType.id} key={i}>
                      <ProjectTypeCard projectType={projectType}/>
                    </Link>
                  )
                })}
              </div>
            }
          </div>
        </div>
        </>

    </div>
  )
}
